<template>
    <div class="body-box" v-track="{ event: 'browse', route: $route, isButton: false }">
        <div class="main-content">
            <Row :gutter="20" index="">
                <Col span="24">
                <Form ref="getHistoryForm" :model="getHistoryForm" label-position="left" :label-width="70" inline>
                    <FormItem label="城市" :label-width="50">
                        <Select v-model="getHistoryForm.cityCode" clearable  style="width:200px;margin-right: 48px;">
                            <Option v-for="item in cityList" :value="item.cityCode" :key="item.cityCode">{{ item.cityName }}
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem label="估价对象" :label-width="70" style="margin-right: 48px;">
                        <Input type="text" maxlength="50" v-model="getHistoryForm.evlTitle" placeholder="请输入">
                        </Input>
                    </FormItem>
                    <FormItem label="小区名称" style="margin-right: 48px;">
                        <Input type="text" maxlength="50" v-model="getHistoryForm.lpName" placeholder="请输入">
                        </Input>
                    </FormItem>
                    <FormItem label="提交时间" style="margin-right: 0px;">
                        <DatePicker :options="dataLimit2" format="yyyy-MM-dd" type="daterange" placement="bottom-end"
                            @on-change='getHistorySelectDate' placeholder="请选择"></DatePicker>
                    </FormItem>


                    <FormItem :label-width="24">
                        <Button type="primary " @click="getHistory(1)">查询</Button>
                    </FormItem>
                </Form>
                </Col>
                <Col span="">
                </Col>
            </Row>
            <Row :gutter="16" index="">
                <Col span="24">
                <Table :columns="columns" :data="Mydata" v-loading="isLoading">
                    <template slot-scope="{ row }" slot="evlTitle">
                        <Tooltip max-width="400" :transfer="true">
                            <div class="evlTitle-box" @click="goResult(row)">
                                <div class="red-point" v-if="row.hasRedPoint"></div>
                                {{ row.evlTitle | filter_evlTitle }}
                            </div>
                            <div slot="content">
                                <div>{{ row.evlTitle }}</div>
                            </div>
                        </Tooltip>
                    </template>
                    <template slot-scope="{ row }" slot="action" >
                        <Poptip confirm placement="bottom" width="300" :transfer="true" title="确定删除?" @on-ok="remove(row)"
                            @on-cancel="cancel">
                            <a>删除</a>
                        </Poptip>
                    </template>
                </Table>
                </Col>
            </Row>
            <Row :gutter="16" style="margin-top: 24px;" type="flex" justify="end">
                <Col span="24">
                <el-pagination background layout="prev, pager, next, jumper" @current-change="currentChange"
                    :current-page="getHistoryForm.current" :total="totalPage">
                </el-pagination>
                </Col>
            </Row>
        </div>
    </div>
</template>

<script>
import { evaluationHistoryPage, delEvaluationHistory,getEvlHisCity } from '@/api/pledge'
import {
    formatTime,
    formatDate,
    _debounce
} from "@/utils/";
import dataReports from "@/mixins/dataReports";

export default {
    mixins: [dataReports],

    data() {
        return {
            // cityList: [
            //     { label: '全部', value: 0 },
            //     { label: '广州市', value: '440100' },
            //     { label: '佛山市', value: '440600000000' },
            //     { label: '东莞市', value: '441900000000' },
            // ],
            cityList:[],
            getHistoryForm: {
                cityCode: 0,
                evlTitle: '',
                assessTimeStart: '',
                assessTimeEnd: '',
                current: 1,
                size: 10,
                evaluationIsBatch: 0,
            },
            totalPage: 0,
            //时间限制
            dataLimit2: {
                disabledDate(date) {
                    return (
                        date.valueOf() > Date.now()
                    );
                },
            },
            columns: [
                {
                    title: '序号',
                    key: "index",
                    width: 100,
                },

                {
                    title: "提交时间",
                    key: "createTime",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.createTime ? formatTime(params.row.createTime, '/') : formatTime(
                                params.row.createTime, '/')
                        );
                    },
                },
                {
                    title: '小区名称',
                    key: 'lpName',
                    width: 200,
                    tooltip: true
                },
                {
                    title: '估价对象',
                    key: 'evlTitle',
                    width: 365,
                    tooltip: true,
                    slot: 'evlTitle',

                },
                {
                    title: '单价（元/㎡） ',
                    key: "evlUnitPrice",
                    render: (h, params) => {
                        let text = ''
                        switch (params.row.assessResult) {
                            case 0:
                                text = '正估价中'
                                break;
                            case 1:
                                text = (params.row.evlUnitPrice * 1).toFixed(0)
                                break;
                            case 2:
                                text = '暂无法估价'
                                break;
                            case 3:
                                text = (params.row.evlUnitPrice * 1).toFixed(0) + '(' + '参考价' + ')'
                                break;

                            default:
                                break;
                        }
                        return h(
                            "span",
                            text
                        );
                    },
                },

                {
                    title: "总价（万元）",
                    key: "evlTotalPrice",
                    render: (h, params) => {
                        let text = ''
                        switch (params.row.assessResult) {
                            case 0:
                                text = '正估价中'
                                break;
                            case 1:
                                text = (params.row.evlTotalPrice * 1).toFixed(2)
                                break;
                            case 2:
                                text = '暂无法估价'
                                break;
                            case 3:
                                text = (params.row.evlTotalPrice * 1).toFixed(2) + '(' + '参考价' + ')'
                                break;

                            default:
                                break;
                        }
                        return h(
                            "span",
                            text
                        );
                    },
                },
                {
                    title: '操作',
                    slot: 'action'
                },
            ],
            isLoading: false,
            Mydata: [
            ]
        };
    },
    filters: {
        filter_evlTitle(val) {
            let texts = ''
            if (val.length > 24) {
                texts = val.substring(0, 24) + '...' // 进行数字截取或slice截取超过长度时以...表示
            } else {
                texts = val
            }
            return texts;
        }
    },
    computed: {
    // cityList () {
    //     return this.$store.state.cityList.cityList
    // },
    //
    },
    created() {

    },
    mounted() {
        this.getHistory(1)
        this.getCityList()
    },
    methods: {

   async getCityList(){
   let params = {
   isBatch:0 //0:单个估价 1:批量估价
   }
   let res = await  getEvlHisCity(params)
   this.cityList = res
    },
        goResult(row) {

            this.$router.push({
                path: '/Empowerment/pledge/houseProperty/resultValuation',
                query:
                {
                    cityCode: row.cityCode,
                    sampleHistoryId: row.historyId
                }
            })
        },
        //日期选择后的函数
        getHistorySelectDate(date) {
            this.getHistoryForm.assessTimeStart = date[0]
            this.getHistoryForm.assessTimeEnd = date[1]
        },
        //获取历史
        async getHistory(current) {
            this.isLoading = true
            this.getHistoryForm.current = current
            let params = Object.assign({}, this.getHistoryForm)
            if (params.cityCode == 0) {
                params.cityCode = ''
            }

            let data = await evaluationHistoryPage(params)
            this.Mydata = data.records.map((item, index) => {
                item.index = (index + 1) + this.getHistoryForm.current * 10 - 10
                return item
            })
            this.isLoading = false
            this.getHistoryForm.current = data.current
            this.totalPage = data.total
        },
        currentChange(page) {
            this.getHistory(page)
        },
        async remove(row) {//删除
            let params = {
                id: row.historyId,
                cityCode: row.cityCode
            }
            let res = await delEvaluationHistory(params)
            if (res) {
                this.$msg.success({ text: "删除成功" });
                this.getHistory(1)
            } else {
                this.$msg.error({ text: "删除失败" });

            }
        },
        cancel() {//删除取消

        },
    }
};
</script>

<style scoped lang="scss">
.body-box {
    background: #EFF1F6;
    height: 100%;
    padding: 24px;
    min-width: 1600px;

    .main-content {
        background: #FFFFFF;
        height: 100%;
        border-radius: 10px 10px 10px 10px;
        padding: 24px;

        // margin: 24px;
    }

    .evlTitle-box {
        font-family: PingFangSC-Regular-, PingFangSC-Regular;
        font-weight: normal;
        color: #0077FF;
        cursor: pointer;

        .red-point {
            background: red;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            float: left;
            position: absolute;
            left: -13px;
            top: 5px;

        }
    }
}
</style>
